import React, {Component} from 'react';
import {
    Modal,
    FormControl,
    Button, FormGroup, Row, Col
} from 'react-bootstrap';
import Select from "../Select";
import * as moment from 'moment';
import * as Datetime from "react-datetime";
import Switcher from "../Switch";

export default class OrderButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lggs: props.products ? props.products : [],
            hotspl: props.hotspl ? props.hotspl : [],
            orderModalShow: false,
            greenModal: false,
            redModal: false,
            amounts: {},
            note: undefined,
            selectedHotspl: 0,
            selectedLgg: -1,
            price: 0.00,
            priceInclVat: 0.00,
            agreedPrice: 0.00,
            gifts: props.gifts,
            displays: props.displays,
            calendars: props.calendars,
            selectedCalendar: props.calendars && props.calendars[0] ? props.calendars[0].id : '1',
            selectedDisplay: props.displays && props.displays[0] ? props.displays[0].id : '1',
            callTime: moment().format("HH:mm"),
            callDate: moment().format("YYYY-MM-DD"),
            selected: {},
            filter: ""
        };
        this.tmpCalendarSelected = 1;
        this.tmpDisplaySelected = 1;
        this.tempSelected = {};
    }

    componentWillReceiveProps(props) {
        this.setState({lggs: props.products, hotspl: props.hotspl,
            selectedHotspl: props.hotspl[0].value, displays: props.displays, calendars: props.calendars, gifts: props.gifts,
        selectedCalendar: props.calendars[0] ? props.calendars[0].id : '1', selectedDisplay: props.displays[0] ? props.displays[0].id : '1'});
    }

    orderHandler = () => {
        let ordered = [];
        Object.keys(this.state.amounts).forEach(key => {
            ordered.push(this.state.amounts[key]);
        });
        this.props.setData("ordered", ordered);
        this.props.setData("status", 6);
        this.props.setData("hotspl", this.state.selectedHotspl);
        this.props.setData("note", this.state.note || "");
        this.props.setData("totalPrice", this.state.agreedPrice > 0 ? this.state.agreedPrice : this.state.priceInclVat);
        this.orderModalHide();
        this.props.next();
    };

    orderModalHide = () => {
        this.setState({orderModalShow: false});
    };

    orderModalShow = () => {
        this.setState({orderModalShow: true});
    };

    setAmount = (item, event) => {
        let newAmounts = this.state.amounts;
        let {price, priceInclVat} = this.state;
        let product = newAmounts[item.product_id];

        if (product) {
            price -= parseFloat(product.amount) * parseFloat(item.price);
            priceInclVat -= parseFloat(product.amount) * parseFloat(item.priceInclVat);
        }

        if (isNaN(price)) {
            price = 0.00;
        }

        if (isNaN(priceInclVat)) {
            priceInclVat = 0.00;
        }

        if (event.target.value) {
            price += parseFloat(event.target.value) * parseFloat(item.price);
            priceInclVat += parseFloat(event.target.value) * parseFloat(item.priceInclVat);
        }

        if (isNaN(price)) {
            price = 0.00;
        }

        if (isNaN(priceInclVat)) {
            priceInclVat = 0.00;
        }

        newAmounts[item.product_id] = {
            amount: event.target.value || 0,
            price: item.price,
            priceInclVat: item.priceInclVat,
            product_id: item.product_id
        };
        this.setState({
            amounts: newAmounts,
            price,
            priceInclVat
        });
    };

    setNote = (event) => {
        this.setState({note: event.target.value});
    };

    filterProducts = (event) => {
        this.setState({filter: event.target.value});
    };

    setAgreedPrice = (event) => {
        this.setState({agreedPrice: event.target.value});
    };

    changeSelected = (value) => {
        this.setState({selectedHotspl: value});
    };

    changeSelectedLgg = (event) => {
        this.setState({selectedLgg: event.target.value});
    };

    cancelFilter = () => {
        this.setState({selectedLgg: -1, filter: ""});
    };

    greenHandler = () => {
        this.props.setData("status", 2);
        this.props.setData("reason", this.state.note || "");
        this.props.next();
    };

    greenHide = () => {
        this.setState({greenModal: false});
    };

    greenCancel = () => {
        this.greenHide();
    };

    greenShow = () => {
        this.setState({greenModal: true});
    };

    redHandler = () => {
        this.setState({selected: this.tempSelected}, () => {
            this.props.setData("status", 4);
            this.props.setData("nextCallDate", this.state.callDate);
            this.props.setData("nextCallTime", this.state.callTime);
            this.props.setData("calendar", this.state.selectedCalendar.toString());
            this.props.setData("display", this.state.selectedDisplay.toString());
            this.props.setData("note", this.state.note);
            this.props.setData("gifts", this.state.selected);
            this.props.next();
        });
    };

    redHide = () => {
        this.setState({redModal: false});
    };

    redCancel = () => {
        this.redHide();
    };

    redShow = () => {
        this.setState({redModal: true});
    };

    handleDateChange = (event) => {
        let newState = "";
        if (event instanceof moment) {
            newState = event.format("YYYY-MM-DD");
        } else {
            newState = event;
        }
        this.setState({callDate: newState})
    };

    handleTimeChange = (event) => {
        let newState = "";
        if (event instanceof moment) {
            newState = event.format("H:mm");
        } else {
            newState = event;
        }
        this.setState({callTime: newState})
    };

    changeCalendar = (selected) => {
        this.tmpCalendarSelected = selected;
        this.setState({selectedCalendar: this.tmpCalendarSelected});
    };

    setGift = (name, state) => {
        this.tempSelected[name] = state;
    };

    changeDisplay = (selected) => {
        this.tmpDisplaySelected = selected;
        this.setState({selectedDisplay: this.tmpDisplaySelected});
    };

    //<editor-fold desc="orange button handlers">
    orangeHandler = () => {
        this.props.setData("status", 3);
        this.props.setData("nextCallTime", this.state.callTime);
        this.props.setData("nextCallDate", this.state.callDate);
        this.props.setData("note", this.state.note);
        this.orangeHide();
        this.props.next();
    };

    orangeHide = () => {
        this.setState({orangeModal: false});
    };

    orangeCancel = () => {
        this.orangeHide();
    };

    orangeShow = () => {
        this.setState({orangeModal: true});
    };
    //</editor-fold>


    render() {
        let i = 0;
        return (
            <div>
                <div className="row main-btns">
                    <div className="col-md-3 col-xs-12">
                        <button type="button" className="btn btn-primary btn-xlg"
                                onClick={this.orderModalShow.bind(this)}>
                            Objednávka
                        </button>
                        <Modal
                            show={this.state.orderModalShow}
                            onHide={this.orderModalHide}
                            bsSize={"lg"}
                        >
                            <Modal.Header className="bg-primary">
                                <Modal.Title>Objednávka</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{maxHeight: "580px"}}>
                                <Row style={{height: "116px"}}>
                                    <Col md={6} style={{height: "100%"}}>
                                        <FormControl
                                            {...(this.state.note ? {value: this.state.note} : {})}
                                            id="note"
                                            style={{height: "100%"}}
                                            componentClass="textarea"
                                            placeholder="Poznámka k objednávke"
                                            onChange={this.setNote}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={4}>Spôsob platby</Col>
                                            <Col md={8}>
                                                <Select
                                                    options={this.state.hotspl}
                                                    value={this.state.selectedHotspl}
                                                    onChange={this.changeSelected.bind(this)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>suma bez DPH</Col>
                                            <Col md={3}
                                                 style={{textAlign: "right"}}>{this.state.price.toFixed(2)} €</Col>
                                            <Col md={3}>suma s DPH</Col>
                                            <Col md={3} style={{
                                                textAlign: "right",
                                                fontWeight: "bold"
                                            }}>{this.state.priceInclVat.toFixed(2)} €</Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormControl
                                                    id={"agreedPrice"}
                                                    type={"number"}
                                                    placeholder={"Dohodnutá suma s DPH " + (this.state.priceInclVat > 0 ? "(" + this.state.priceInclVat.toFixed(2) + " €)" : "")}
                                                    {...(this.state.agreedPrice > 0 ? {value: this.state.agreedPrice} : {value: ""})}
                                                    onChange={this.setAgreedPrice}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={5}>
                                        <FormControl
                                            id={"filterName"}
                                            placeholder={"Filter produktov podla názvu"}
                                            onChange={this.filterProducts}
                                            value={this.state.filter}
                                        />
                                    </Col>
                                    <Col md={7}>
                                        <Row>
                                            {/*<Col md={9}>
                                                <Select
                                                    options={this.state.lggs}
                                                    value={this.state.selectedLgg}
                                                    onChange={this.changeSelectedLgg.bind(this)}
                                                    showDefault={true}
                                                />
                                            </Col>*/}
                                            <Col md={9}>
                                                <FormGroup controlId="lggFilter">
                                                    <FormControl componentClass="select" placeholder="-1" value={this.state.selectedLgg} onChange={this.changeSelectedLgg}>
                                                        <option value={-1}>---</option>
                                                        {this.state.lggs.map(option => (
                                                            <option value={option.id} key={option.id}>{option.value}</option>
                                                        ))}
                                                    </FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}><Button onClick={this.cancelFilter} style={{width: "100%"}}>Zrušiť</Button></Col>
                                        </Row>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Row>
                                                <Col md={12}>
                                                    <Row style={{textAlign: "center", fontWeight: "bold"}}>
                                                        <Col md={7} style={{
                                                            padding: "7px 0 7px 0",
                                                            borderRight: "solid 1px",
                                                            borderBottom: "solid 1px"
                                                        }}>Produkt</Col>
                                                        <Col md={3}>
                                                            <Row>
                                                                <Col md={6} style={{
                                                                    padding: "7px 0 7px 0",
                                                                    borderRight: "solid 1px",
                                                                    borderBottom: "solid 1px"
                                                                }}>Cena</Col>
                                                                <Col md={6} style={{
                                                                    padding: "7px 0 7px 0",
                                                                    borderRight: "solid 1px",
                                                                    borderBottom: "solid 1px"
                                                                }}>Cena s DPH</Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={2} style={{
                                                            padding: "7px 0 7px 0",
                                                            borderBottom: "solid 1px"
                                                        }}>Množstvo</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row style={{maxHeight: "480px", overflowY: "auto", overflowX: "hidden"}}>
                                                {this.state.lggs.map((lgg, index) => {
                                                    let products = lgg.products;
                                                    let count = 0;
                                                    products.map(item => {
                                                        if (item.product_name.toLowerCase().includes(this.state.filter.toLowerCase()) && (lgg.id.toString() === this.state.selectedLgg.toString() || this.state.selectedLgg.toString() === "-1")) count++;
                                                        return null;
                                                    });
                                                    if (count > 0) {
                                                        i = 0;
                                                        return (
                                                            <div key={index}>
                                                                <Row style={{
                                                                    height: "40px",
                                                                    paddingTop: "10px",
                                                                    borderBottom: "solid 2px"
                                                                }}>
                                                                    <Col md={12} style={{
                                                                        textAlign: "center",
                                                                        fontWeight: "bold"
                                                                    }}>
                                                                        {(lgg.groupName.length > 0 ? lgg.groupName : "Nezaradené").toUpperCase()}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        {products.map((item, index) => {
                                                                            if (item.product_name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                                                                                return (
                                                                                    <Row key={"product" + index}
                                                                                         style={{
                                                                                             backgroundColor: (i++ % 2 === 0 ? "#e4e2e2" : "white"),
                                                                                             borderBottom: "solid 1px",
                                                                                             borderColor: "#bebcbc"
                                                                                         }}>
                                                                                        <Col md={12}>
                                                                                            <Row>
                                                                                                <Col md={7}>{item.product_id + " " + item.product_name}</Col>
                                                                                                <Col md={3}>
                                                                                                    <Row
                                                                                                        style={{textAlign: "right"}}>
                                                                                                        <Col
                                                                                                            md={6}>{parseFloat(item.price).toFixed(2)} €</Col>
                                                                                                        <Col
                                                                                                            md={6}>{parseFloat(item.priceInclVat).toFixed(2)} €</Col>
                                                                                                    </Row>
                                                                                                </Col>
                                                                                                <Col md={2}>
                                                                                                    <Row>
                                                                                                        <Col md={12}>
                                                                                                            <FormControl
                                                                                                                id={"item" + index}
                                                                                                                type={"number"}
                                                                                                                placeholder={"0"}
                                                                                                                {...(this.state.amounts[item.product_id] ? {defaultValue: this.state.amounts[item.product_id].amount} : {})}
                                                                                                                onChange={this.setAmount.bind(this, item)}/>
                                                                                                        </Col>
                                                                                                        {/*<Col md={2}>{item.unit}</Col>*/}
                                                                                                    </Row>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            {<Row>
                                                                                                <Col md={10}
                                                                                                     dangerouslySetInnerHTML={{__html: item.product_description}}>
                                                                                                </Col>
                                                                                            </Row>}
                                                                                        </Col>
                                                                                    </Row>

                                                                                )
                                                                            } else {
                                                                                return null;
                                                                            }
                                                                        })}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.orderModalHide}>Zrušiť</Button>
                                <Button className="btn-primary" onClick={this.orderHandler.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div className="col-md-3 col-xs-12">
                        <button type="button" className="btn bg-orange btn-sm" onClick={this.orangeShow.bind(this)}>
                            Volať neskôr
                        </button>

                        <Modal
                            show={this.state.orangeModal}
                            onHide={this.orangeHide}
                        >
                            <Modal.Header className="bg-orange">
                                <Modal.Title>Volať neskôr</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <Row>
                                        <Col md={6}>
                                            <Datetime name={"datum"} dateFormat={"dddd DD. MMMM YYYY"}
                                                      defaultValue={moment()} timeFormat={false}
                                                      onChange={this.handleDateChange.bind(this)} input={true}/>
                                        </Col>
                                        <Col md={6}>
                                            <Datetime name={"cas"} dateFormat={false}
                                                      defaultValue={moment()} timeFormat={"HH:mm"}
                                                      onChange={this.handleTimeChange.bind(this)} input={true}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormControl
                                                id="reason"
                                                componentClass="textarea"
                                                value={this.state.note}
                                                placeholder="Poznámka"
                                                onChange={this.setNote}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.orangeCancel}>Zrušiť</Button>
                                <Button className="btn bg-orange"
                                        onClick={this.orangeHandler.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div className="col-md-3 col-xs-12">
                        <button type="button" className="btn bg-warning-400 btn-xlg"
                                onClick={this.redShow.bind(this)}>
                            Ukážka
                        </button>
                        <Modal
                            show={this.state.redModal}
                            onHide={this.redHide}
                            bsSize={"large"}
                        >
                            <Modal.Header className="bg-warning-400">
                                <Modal.Title>Ukážka</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <Row>
                                        <Col md={6}>
                                            <Datetime name={"datum"} dateFormat={"dddd DD. MMMM YYYY"}
                                                      defaultValue={moment()} timeFormat={false}
                                                      onChange={this.handleDateChange.bind(this)} input={true}/>
                                        </Col>
                                        <Col md={6}>
                                            <Datetime name={"cas"} dateFormat={false}
                                                      defaultValue={moment()} timeFormat={"HH:mm"}
                                                      onChange={this.handleTimeChange.bind(this)} input={true}/>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={12}>
                                            <FormControl
                                                id="reason"
                                                componentClass="textarea"
                                                value={this.state.note}
                                                placeholder="Poznámka"
                                                onChange={this.setNote}
                                            />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Select
                                                    label={"Typ kalendára"}
                                                    options={this.state.calendars}
                                                    value={this.state.selectedCalendar}
                                                    onChange={this.changeCalendar.bind(this)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Select
                                                    label={"Typ ukážky"}
                                                    options={this.state.displays}
                                                    value={this.state.selectedDisplay}
                                                    onChange={this.changeDisplay.bind(this)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Col md={12}>
                                            Darčeky
                                            <FormGroup>
                                                <Row>
                                                    <Col md={6}>
                                                        {this.state.gifts
                                                            .filter((item, index) => index % 2 === 0)
                                                            .map(item => (
                                                                <Switcher id={item.id + ""} key={item.id}
                                                                          label={item.value}
                                                                          checked={this.state.selected[item.id] === true}
                                                                          position="right" onChange={this.setGift}/>
                                                            ))}
                                                    </Col>
                                                    <Col md={6}>
                                                        {this.state.gifts
                                                            .filter((item, index) => index % 2 === 1)
                                                            .map(item => (
                                                                <Switcher id={item.id + ""} key={item.id}
                                                                          label={item.value}
                                                                          checked={this.state.selected[item.id] === true}
                                                                          position="right" onChange={this.setGift}/>
                                                            ))}
                                                    </Col>
                                                </Row>

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.redCancel}>Zrušiť</Button>
                                <Button className="bg-warning-400" onClick={this.redHandler.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div className="col-md-3 col-xs-12">
                        <button type="button" className="btn btn-success btn-xlg"
                                onClick={this.greenShow.bind(this)}>
                            Nemá záujem
                        </button>
                        <Modal
                            show={this.state.greenModal}
                            onHide={this.greenHide}
                        >
                            <Modal.Header className="btn-success">
                                <Modal.Title>Nemá záujem</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormControl
                                    {...(this.state.note ? {value: this.state.note} : {})}
                                    id="reason"
                                    style={{height: "100%"}}
                                    componentClass="textarea"
                                    placeholder="Dôvod nezáujmu"
                                    onChange={this.setNote}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.greenCancel}>Zrušiť</Button>
                                <Button className="btn-success" onClick={this.greenHandler.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}

