import React, {Component} from 'react';
import {
    Modal,
    FormControl,
    Button, Col, Checkbox, Row
} from 'react-bootstrap';
import axios from "axios";

export default class InitialButtons extends Component {
    constructor() {
        super();
        this.state = {
            skipModalShow: false,
            exclude: false
        };
        this.reason = '';
    }

    componentDidMount() {
        let data={cxToken:window.localStorage.getItem('token_3cx'),extension:window.localStorage.getItem("extension")}
        axios.post(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token')
            + "&action=HANGUP",data).then(
            res => {
            }
        ).catch(error => {
                console.log(error)
            }
        );
    }

    skipHandler = () => {
        if (String(this.reason).length > 0) {
            this.skipModalHide();
            this.props.setData("status", 1);
            this.props.setData("note", this.reason);
            this.props.setData("exclude", this.state.exclude);
            this.setState({exclude: false});
            this.props.skip();
        }
    };

    nextHandler = (numberFrom) => {
        let data={cxToken:window.localStorage.getItem('token_3cx'),extension:window.localStorage.getItem("extension"),numberFrom:numberFrom,numberTo:this.props.number}
        axios.post(window.localStorage.getItem('url') + "/CallcentrumQueueServlet?token=" + window.localStorage.getItem('token')
            + "&action=CALL",data).then(
            res => {
                this.props.next();
            }
        ).catch(error => {
                console.log(error)
            }
        );

        // axios.get('https://213.81.139.77/api/api.php?username=tabicorp&password=' + window.localStorage.getItem("token_3cx") + '&action=originate&srcSipUsername=' + window.localStorage.getItem("extension") +
        //     '&dstNum=' + this.props.number +
        //     '&dialerCid=' + this.props.number +
        //     '&dialerName=' + this.props.number +
        //     '&overrideCallerId=' + numberFrom).then(
        //     res => {
        //         this.props.next();
        //     }
        // ).catch(error => {
        //     this.props.next();
        //     }
        // );

    };

    skipModalHide = () => {
        this.setState({skipModalShow: false});
    };

    skipModalShow = () => {
        this.setState({skipModalShow: true});
    };

    setReason = (event) => {
        this.reason = event.target.value;
    };

    toggleExclude = () => {
        this.setState({exclude: !this.state.exclude});
    }

    render() {
        return (
            <div>
                <div className="row main-btns">
                    <div className="col-md-3 col-xs-12">
                        <button type="button" className="btn btn-warning btn-xlg"
                                onClick={this.skipModalShow.bind(this)}>
                            <i className="icon-redo2 position-left"/>Preskoč
                        </button>
                        <Modal
                            show={this.state.skipModalShow}
                            onHide={this.skipModalHide}
                        >
                            <Modal.Header className="bg-primary">
                                <Modal.Title>Dôvod preskočenia</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md={12}>
                                        <FormControl
                                            id="reason"
                                            componentClass="textarea"
                                            placeholder="Zadajte dôvod preskočenia kontaktu (povinné)"
                                            onChange={this.setReason}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Checkbox label={"Vylúčiť z fronty"}
                                                  checked={Boolean(this.state.exclude) === true} position="right"
                                                  onChange={this.toggleExclude}>{"Vylúčiť z fronty"}</Checkbox>

                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={this.skipModalHide}>Zrušiť</Button>
                                <Button className="btn-primary" onClick={this.skipHandler.bind(this)}>Odoslať</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                        <div className="col-md-3">
                            <button type="button" className="btn btn-success btn-xlg" onClick={this.nextHandler.bind(this, window.localStorage.getItem("number1"))}>
                                {window.localStorage.getItem("number1")}
                            </button>
                        </div>
                        <div className="col-md-3">
                            <button type="button" className="btn btn-primary btn-xlg" onClick={this.nextHandler.bind(this, window.localStorage.getItem("number2"))}>
                                {window.localStorage.getItem("number2")}
                            </button>
                        </div>
                        <div className="col-md-3">
                            <button type="button" className="btn btn-primary btn-xlg" onClick={this.nextHandler.bind(this, window.localStorage.getItem("number3"))}>
                               {window.localStorage.getItem("number3")}
                            </button>
                        </div>
                </div>
            </div>
        );
    }
}

