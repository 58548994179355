import React, {Component} from 'react';
import 'moment/locale/sk';

export default class App extends Component {
    render(){
        return(
            <div>
                <span>{window.localStorage.getItem("name")}&nbsp;</span>
            <button className="btn btn-danger btn-nav" onClick={() => window.dispatchEvent(new Event('logout'))}>Odhlásiť</button>
            </div>
        );
    }
}